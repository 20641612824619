.dashboard-container {
  margin-left: 30px;
  margin-top: 30px;
  margin-right: 30px;
  background-color: #fff;
  min-height: 90vh;
  border-radius: 6px;
}

.head-container {
  display: flex;
  justify-content: space-between;
}

.head-container h1 {
  font-size: 18px;
  color: #6aab9c;
}

.mini-container {
  max-width: 1200px;
  margin: auto;
}

.profile-section img {
  width: 60px;
  height: 60px;
  border-radius: 60%;
  margin-top: 4px;
  border: 3px solid #6aab9c;
}

.profile-section h4 {
  margin-top: 14px;
  font-size: 16px;
  color: #6aab9c;
}

.profile-section {
  display: flex;
  gap: 1em;
}

.content-container {
  margin-top: 4%;
}

.content-container h1 {
  font-size: 18px;
  color: #6aab9c;
  text-decoration: underline;
}

.data-table {
  margin-top: 20px !important;
}

.ant-table-thead > tr > th {
  position: relative;
  color: #fff !important;
  font-weight: 500;
  text-align: left;
  background: #00abc5 !important;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}

.dashboard-paragraph {
  text-align: justify !important;
  line-height: 32px !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}
