.heading h5 {
  font-size: 16px;
  color: #ff5634;
  position: relative;
}

.heading h5::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 220px;
  width: 20%;
  height: 4px;
  border-radius: 5px;
  transform: translateY(-50%);
  background-color: #ff5634;
}

.aboutImg img {
  width: 100%;
  border-radius: 10px;
}

.aa-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
